import baseApi from "@/api/baseApi.js";

// export const myAction = async ({ commit }) => {

// }

export const loginUser = async ({ commit }, user ) => {
    const { email, password } = user;
    try {
        const { data: { user, token, refreshToken }} = await baseApi.post('auth', { email, password })
        

        const dashboards = await getDashboardsToAPI(user.Role.name, token)

        commit('loginUser', {user, token, refreshToken, dashboards})

        return { ok: true }
    } catch (error) {

        console.log(error);
        if ( !error.response )
            return { ok: false, message: 'Error interno, intente más tarde.' }
        return { ok: false, message: error.response.data.msg ? error.response.data.msg : error.response.data.message}
    }
}

export const checkAuthentication = async ({ commit }) => {

    const refreshToken = localStorage.getItem('refreshToken')

    if( !refreshToken ) {
        commit('logout')
        return { ok: false, message: 'No hay token' }
    }

    try {
        
        const { data: { user, token } } = await baseApi.post('auth/refresh', null, { headers: {'refresh-token': refreshToken}})
        
        const dashboards = await getDashboardsToAPI(user.Role.name, token)
        
        commit('loginUser', { user, token, refreshToken, dashboards })
        
        return { ok: true }

    } catch (error) {
        console.log(error)
        commit('logout')
        return { ok: false, message: error.response }
    }

}

const getDashboardsToAPI = ( async (role, token) => {

    if ( role === 'ADMIN_USER') {
        const { data: { dashboards }} = await baseApi.get('/dashboard', { headers: {'x-token': token}})
        return dashboards
    }

    if ( role !== 'ADMIN_USER') {
        const dashboards = []
        const { data: { data }} = await baseApi.get('/dashboard/user', { headers: {'x-token': token}})
        data.forEach(tablero => {
            const { Dashboard } = tablero
            dashboards.push(Dashboard)
        });
        return dashboards
    }
})
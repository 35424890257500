<script setup>
import useAuth from "./views/auth/composables/useAuth";

  const { authStatus, checkAuthStatus } = useAuth();

  checkAuthStatus();
</script>

<template>
<div class="app">
  <router-view />
</div>
</template>

<style>
#app {
  height: 100vh;
}
.oswald {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
</style>

import isAuthenticatedGuard from '@/views/auth/router/auth-guard.js'

export default {
    name: 'admin',
    redirect: '/admin-options',
    beforeEnter: [ isAuthenticatedGuard ],
    component: () => import(/* webpackChunkName: "Admin Layout" */ '../layouts/AdminLayout.vue'),
    children: [
        {
            name: 'admin-options',
            path: '/admin-options',
            component: () => import(/* webpackChunkName: "Option View" */ '../views/optionsView.vue'),
        },
        {
            name: 'users',
            path: '/users',
            component: () => import(/* webpackChunkName: "Users View" */ '../views/usersView.vue'),
        },
        {
            name: 'edit-user',
            path: '/edit-user/:userId', 
            component: () => import(/* webpackChunkName: "Edit User View" */ '../views/editUserView.vue'),
        },
        {
            name: 'create-user',
            path: '/create-user', 
            component: () => import(/* webpackChunkName: "Create User View" */ '../views/createUserView.vue'),
        },
        {
            name: 'dashboards',
            path: '/dashboards',
            component: () => import(/* webpackChunkName: "Dashboards View" */ '../views/Dashboard/dashboardView.vue'),
        },
        {
            name: 'create-dashboard',
            path: '/create-dashboard',
            component: () => import(/* webpackChunkName: "Create Dashboards View" */ '../views/Dashboard/createDashboardView.vue'),
        },
        {
            name: 'update-dashboard',
            path: '/update-dashboard',
            component: () => import(/* webpackChunkName: "Update Dashboards View" */ '../views/Dashboard/updateDashboardView.vue'),
        },
        {
            name: 'projects',
            path: '/projects',
            component: () => import(/* webpackChunkName: "Projects View" */ '../views/Project/projectView.vue'),
        },
        {
            name: 'create-project',
            path: '/create-project',
            component: () => import(/* webpackChunkName: "Create Project View" */ '../views/Project/createProjectView.vue'),
        },
        {
            name: 'update-project',
            path: '/update-project',
            component: () => import(/* webpackChunkName: "Update project View" */ '../views/Project/updateProjectView.vue'),
        },

    ]
}

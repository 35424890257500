// export const myAction = async ( state ) => {

// }

export const loginUser = async ( state, { user, token, refreshToken, dashboards}) => {
    
    state.idToken = token
    state.refreshToken = refreshToken
    state.dashboards = dashboards

    localStorage.setItem( 'refreshToken', refreshToken )
    state.user = user

    state.status = 'authenticated'
}

export const updateFirtsLogin = (state) => {

    state.user.firstLogin = false

}

export const logout = (state) => {

    state.user = null
    state.idToken = null
    state.refreshToken = null
    state.dashboards = []
    state.status = 'not-authenticated'

    localStorage.removeItem('refreshToken')
}
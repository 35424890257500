import adminRouter from '../../admin/router'
import dashboardRouter from '../../dashboard/router'
import isAuthenticatedGuard from '@/views/auth/router/auth-guard.js'
export default {
    
    name: 'panel',
    beforeEnter: [ isAuthenticatedGuard ],
    component: () => import(/* webpackChunkName: "Auth Layout" */ '../layouts/PanelLayout.vue'),
    children: [
        {
            path: '/dashboard',
            ...dashboardRouter
        },
        {
            path: '/administracion',
            ...adminRouter
        },
        {
            path: '/edit-user',
            name: 'update-user',
            component: () => import(/* webpackChunkName: "Edit user Layout" */ '../components/updateUser.vue'),
       }
    ]

}
import { createRouter, createWebHashHistory } from 'vue-router'

import authRouter from '../views/auth/router'
import panelRouter from '../views/panel/router'
import isAuthenticatedGuard from '../views/auth/router/auth-guard.js'
const routes = [
  {
    path: '/',
    redirect: '/auth'
  },
  {
    path: '/auth',
    ...authRouter
  },
  {
    path: '/panel',
    beforeEnter: [ isAuthenticatedGuard ],
    ...panelRouter,
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


export default router

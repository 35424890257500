import isAuthenticatedGuard from '@/views/auth/router/auth-guard.js'


export default {
    name: 'dashboard',
    redirect: '/home',
    beforeEnter: [ isAuthenticatedGuard ],
    component: () => import(/* webpackChunkName: "Login" */ '@/views/dashboard/layouts/DashboardLayout.vue'),    
    children: [
        {
            name: 'home',
            path: '/home',
            component: () => import(/* webpackChunkName: "Home View" */ '../views/homeDashboardView.vue'),
        },
        {
            name: 'tablero',
            path: '/tablero/:id',
            component: () => import(/* webpackChunkName: "Users View" */ '../views/tableroView.vue'),
        }
    ]
}
import { getDashboards } from "@/views/panel/store/getters";
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex"

const useAuth = () => {
    const store = useStore();
    const router = useRouter();
    const loginUser = async( userCredentials ) => {
        const resp = await store.dispatch( 'authModule/loginUser', userCredentials )
        return resp
    }

    const checkAuthStatus = async() => {
        const resp = await store.dispatch('authModule/checkAuthentication')
        return resp
    }

    const logout = () => {
        store.commit('authModule/logout')
        router.push({name: 'login'})
    }

    return {
        loginUser,
        checkAuthStatus,
        logout,

        authStatus: computed( () => store.getters['authModule/currentState']),
        user: computed( () => store.getters['authModule/getUser']),
        dashboards: computed( () => store.getters['authModule/getDashboards']),
    }
}

export default useAuth
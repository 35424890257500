// export const myGetter = ( state ) => {
//     return state
// }

export const currentState = ( state ) => {
    return state.status
}

export const tokenValue = ( state ) => {
    return state.idToken
}

export const getUser = ( state ) => {
    return state.user
}

export const getDashboards = ( state ) => {
    return state.dashboards
}